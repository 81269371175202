.cartItemInfo {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;

  &:hover {
    text-decoration: none;
  } */
}
.cartContent {
  padding: 0rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3rem;
}
.cartItem {
  display: flex;
  justify-content: space-between;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;

  &:hover {
    text-decoration: none;
  }
}
.cartItemTitle {
  margin: 0;

  padding: 0;
}
.cartItemPrice {
  margin: 0;
  padding: 0;
}
.deleteIcon {
  color: red;
  cursor: pointer;
  font-size: 30px;
  &:hover {
    color: darkred;
  }
}
.error {
  color: red;
  margin: 0;
  margin-bottom: -22px;
  padding-left: 0.5rem;
}
.breakdown {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.submitButton {
  margin-top: 3rem;
}
.spinnerContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
}
.carticon {
  margin-right: 0.25rem;
  margin-bottom: 0.45rem;
}
.title {
  margin-bottom: 0;
}
